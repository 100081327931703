/*
Template Name: Ubold - Responsive Bootstrap 4 Admin Dashboard
Version: 5.0.0
Author: CoderThemes
Email: support@coderthemes.com
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";
