//
// joyride.scss
//

.joyride-backdrop {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.joyride-step__header {
    padding: 0 8px !important;
}

.backdrop-container {
    z-index: 1001 !important;
}

div.joyride-step__holder {
    border: none;

    .joyride-button {
        background-color: $primary !important;
        background-image: none !important;
        border-color: $primary !important;
        border-radius: $btn-border-radius;
        text-shadow: none !important;
        margin: 0px;
        font-family: $font-family-base;
        color: $white !important;
    }

    .hopscotch-bubble-number {
        background: $danger;
        padding: 0;
        border-radius: 50%;
    }

    .joyride-step__arrow {
        &.joyride-arrow__left {
            border-right: 19px solid $primary;
            border: none;
        }

        &.joyride-arrow__right {
            border-left: 19px solid $primary;
            left: -2px;
            border-left: 0 solid $primary;
        }

        &.joyride-arrow__top {
            border-bottom: 19px solid $primary;
            top: 0;
            border-bottom: 0 solid rgba(0, 0, 0, 0.5);
        }

        &.joyride-arrow__bottom {
            border-top: 19px solid $primary;
            top: -2px;
            border-top: 0 solid rgba(0, 0, 0, 0.5);
        }
    }

    h3 {
        font-family: $font-family-secondary;
        margin-bottom: 10px;
    }

    .joyride-step__container {
        font-family: $font-family-base;
        justify-content: flex-start !important;
        height: auto !important;
        box-shadow: none;
        border-radius: 5px;
    }
}
