//
// Select 2
//

.select2-container {
    .select2-selection--single {
        border: $input-border-width solid $input-border-color !important;
        height: $input-height !important;
        background-color: $input-bg !important;
        box-shadow: $components-shadow-sm !important;
        outline: none !important;
        .select2-selection__rendered {
            line-height: 36px !important;
            padding-left: 12px !important;
            color: $input-color !important;
            text-align: left !important;
        }
        .select2-selection__arrow {
            height: 34px !important;
            width: 34px !important;
            right: 3px !important;
            &::before {
                border-color: #{map-get($grays, "500")} transparent transparent transparent !important;
                border-width: 6px 6px 0 6px !important;
            }
        }
    }

    input::placeholder {
        color: $input-placeholder-color !important;
    }
}

.select2-container--open {
    .select2-selection--single {
        .select2-selection__arrow {
            b {
                border-color: transparent transparent #{map-get($grays, "500")} transparent !important;
                border-width: 0 6px 6px 6px !important;
            }
        }
    }
}

.select2-results__option {
    padding: 6px 12px !important;
    text-align: left !important;
    color: #{map-get($grays, "700")} !important;
    &.select2-results__option--highlighted {
        color: $white !important;
    }
    .select2-results__group {
        color: $body-color !important;
    }
}

.select2-dropdown {
    border: $dropdown-border-width solid $dropdown-border-color !important;
    box-shadow: $box-shadow !important;
    background-color: $dropdown-bg !important;
}

.select2-container--default {
    .select2-search--dropdown {
        padding: 10px !important;
        background-color: $dropdown-bg !important;
        .select2-search__field {
            outline: none !important;
            border: 1px solid $input-border-color !important;
            background-color: $input-bg !important;
            color: $input-color !important;
            text-align: left !important;
        }
    }
    .select2-selection__rendered {
        .select2-selection__placeholder {
            color: $body-color !important;
        }
    }
    .select2-results__option--highlighted[aria-selected] {
        background-color: $primary !important;
        color: $white;
    }
    .select2-results__option[aria-selected="true"] {
        background-color: $dropdown-bg !important;
        color: $dropdown-link-active-color !important;
        &:hover {
            background-color: $primary !important;
            color: $white !important;
        }
    }
}

.select2-container {
    .select2-selection--multiple {
        min-height: $input-height !important;
        border: $input-border-width solid $input-border-color !important;
        background-color: $input-bg !important;
        box-shadow: $components-shadow-sm !important;

        .select2-selection__rendered {
            padding: 1px 10px !important;
        }
        .select2-search__field {
            border: 0 !important;
            color: $input-color !important;
        }
        .select2-selection__choice {
            background-color: $primary !important;
            border: none !important;
            color: $white !important;
            border-radius: 3px !important;
            padding: 0 7px !important;
            margin-top: 6px !important;
        }
        .select2-selection__choice__remove {
            color: $white !important;
            margin-right: 5px !important;
            &:hover {
                color: $white !important;
            }
        }
    }
    .select2-search--inline {
        .select2-search__field {
            margin-top: 7px !important;
        }
    }
}

.select2-selection {
    overflow: hidden !important;
}
.select2-selection__rendered {
    white-space: normal !important;
    word-break: break-all !important;
}

// Dark Mode
body[data-layout-color="dark"] {
    .select2-container {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    border-color: #{map-get($dark-grays, "500")} transparent transparent transparent !important;
                }
            }
        }
    }
    .select2-results__option {
        color: var(--#{$variable-prefix}-body-color) !important;
        &.select2-results__option--highlighted {
            color: $white !important;
        }
        .select2-results__group {
            color: var(--#{$variable-prefix}-body-color) !important;
        }
    }
    .select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    border-color: transparent transparent #{map-get($dark-grays, "500")} transparent !important;
                }
            }
        }
    }
}
