/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */
.dropdown-menu.show {
    top: 0 !important;
}

.add-ticket-popover {
    --max-width: 700px;
    --width: 100%;
}

.content {
    height: calc(100vh - 130px);
    overflow-y: auto;
}

.choose-option-popover {
    --backdrop-opacity: 0;
}

.select-with-filter {
    --width: 300px;
}

.is-invalid-select .selection {
    border: 1px solid red;
    border-radius: 5px;
}

.media-modal {
    --width: 70%;
    --height: 70%;
    app-media {
        overflow-y: scroll;
        ion-grid {
            width: 100%;
            padding: 20px;
        }
    }
}

.choose-category-popover {
    // --height: 50%;
    --width: 50%;
}

.image-cropper-modal {
    --width: 80%;
    --height: 80%;
}

// *************
// icons
// *************
.fa-user-friends {
    color: rgb(223, 223, 10);
    cursor: pointer;
}

.fa-expand-arrows-alt {
    color: rgb(0, 199, 243);
    cursor: pointer;
}

.fa-trash-alt {
    color: red;
    cursor: pointer;
}

.fa-external-link-alt {
    color: rgb(97, 30, 198);
    cursor: pointer;
}

.fa-pen {
    color: rgb(10, 153, 10);
    cursor: pointer;
}

.fa-clone {
    color: #09bf18;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

app-customers,
app-prelanders {
    display: block;
    width: fit-content;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}

.calendar.right{
    display: none !important;
}

// *************
// CUSTOM COLORS
// *************
.danger {
    color: red;
}

// *************
// DROPDOWNS
// *************
.dropdown-xl {
    width: 400px;
}

// *************
// POPOVERS
// *************
.popover-xlg {
    --width: 80%;
}


.popover-xlg-extended{
    --width: 85%;
    --height: 85%;
    z-index: 9999 !important;
}

.popover-md{
    --width: 60%;
    --max-width: 750px;
    --min-width: 350px
}

.popover-md-max-height {
    --min-width: 80%;
    --max-height: 60%;
    --max-width: 750px;
    // --min-width: 300px
}

.popover-md-big {
    --min-width: 80%;
    --min-height: 90%;
    --max-height: 90%;
    --max-width: 90%;
    // --min-width: 300px
}

.popover-center {
    --min-width: 70%;
    --min-height: 50%;
}

.toast-above-all { 
    z-index: 9999999 !important; 
}

.date-range-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
}

.date-range-buttons .btn {
    width: 100%;
    margin-bottom: 10px;
}


@media screen and (max-width: 768px) {
    .card-body{
        padding: 0.5rem;
    }
}