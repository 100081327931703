//
// Datepicker
//
.datepicker {
    padding: 10px !important;
    animation: none;
    td,
    th {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    table {
        tr {
            td {
                &.active.active,
                &.active.disabled,
                &.active.disabled.active,
                &.active.disabled.disabled,
                &.active.disabled:active,
                &.active.disabled:hover,
                &.active.disabled:hover.active,
                &.active.disabled:hover.disabled,
                &.active.disabled:hover:active,
                &.active.disabled:hover:hover,
                .active.disabled:hover[disabled],
                .active.disabled[disabled],
                .active:active,
                .active:hover,
                .active:hover.active,
                .active:hover.disabled,
                .active:hover:active,
                .active:hover:hover,
                .active:hover[disabled],
                .active[disabled],
                span.active.active,
                span.active.disabled,
                span.active.disabled.active,
                span.active.disabled.disabled,
                span.active.disabled:active,
                span.active.disabled:hover,
                span.active.disabled:hover.active,
                span.active.disabled:hover.disabled,
                span.active.disabled:hover:active,
                span.active.disabled:hover:hover,
                span.active.disabled:hover[disabled],
                span.active.disabled[disabled],
                span.active:active,
                span.active:hover,
                span.active:hover.active,
                span.active:hover.disabled,
                span.active:hover:active,
                span.active:hover:hover,
                span.active:hover[disabled],
                span.active[disabled],
                &.today,
                &.today.disabled,
                &.today.disabled:hover,
                &.today:hover {
                    background-color: $primary !important;
                    background-image: none !important;
                    color: $white;
                }

                &.day.focused,
                &.day:hover,
                span.focused,
                span:hover {
                    background: #{map-get($grays, "200")};
                }

                &.new,
                &.old,
                span.new,
                span.old {
                    color: #{map-get($grays, "700")};
                    opacity: 0.4;
                }
            }
        }
    }

    .datepicker-switch:hover,
    .next:hover,
    .prev:hover,
    tfoot tr th:hover {
        background: #{map-get($grays, "200")};
    }
    .datepicker-switch {
        &:hover {
            background: none;
        }
    }
}

.datepicker-dropdown {
    &:after {
        border-bottom: 6px solid $dropdown-bg;
    }
    &:before {
        border-bottom-color: $dropdown-border-color;
    }
    &.datepicker-orient-top {
        &:before {
            border-top: 7px solid $dropdown-border-color;
        }
        &:after {
            border-top: 6px solid $dropdown-bg;
        }
    }
}

//
// Datepicker
//

ngb-datepicker {
    border-color: #{map-get($grays, "300")};
    .ngb-dp-header {
        background-color: #{map-get($grays, "100")};
        padding: 0.25rem 0;
        .ngb-dp-navigation-select {
            & > .form-select {
                margin: 0 3px;
            }
        }
        .ngb-dp-arrow-btn {
            color: #{map-get($grays, "600")};
            &:focus {
                box-shadow: none;
            }
            .ngb-dp-navigation-chevron {
                width: 0.55rem;
                height: 0.55rem;
            }
        }
    }
    .ngb-dp-week .ngb-dp-weekdays {
        background-color: #{map-get($grays, "100")};
    }

    [ngbDatepickerDayView] {
        line-height: 2.5rem;
        background-color: transparent !important;
    }

    .ngb-dp-week-number,
    .ngb-dp-weekday,
    .ngb-dp-day {
        font-style: inherit;
        color: #{map-get($grays, "600")};
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
    }

    .ngb-dp-day {
        &:focus {
            outline: none !important;
        }
    }

    .ngb-dp-day,
    .ngb-dp-today {
        .btn-light {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            font-size: 13px;
            color: #{map-get($grays, "700")};
            &.active {
                border: none;
            }
            &.bg-primary {
                font-weight: bold;
                background-color: $primary !important;
            }
        }
    }

    .ngb-footer-button {
        padding: 8px;
        text-align: right;

        .btn-sm {
            font-size: 0.75rem;
        }
    }
}

.calendar-widget {
    ngb-datepicker {
        width: 100%;
        height: 100%;
        padding: 0 20px;
        padding-bottom: 0px;
        border: none;
        .ngb-dp-header {
            .form-select {
                height: 2rem;
            }
        }
        .ngb-dp-weekday.small {
            font-size: 15px;
        }
        .ngb-dp-content {
            margin-top: 5px;
            .ngb-dp-month {
                width: 100%;
                .ngb-dp-week {
                    height: 40px;
                    justify-content: space-between;

                    .ngb-dp-day {
                        .btn-light {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}

// Dark Mode
body[data-layout-color="dark"] {
    .datepicker {
        table {
            tr {
                td {
                    &.day.focused,
                    &.day:hover,
                    span.focused,
                    span:hover {
                        background: #{map-get($dark-grays, "200")};
                    }

                    &.new,
                    &.old,
                    span.new,
                    span.old {
                        color: #{map-get($dark-grays, "700")};
                        opacity: 0.4;
                    }
                }
            }
        }
        .datepicker-switch:hover,
        .next:hover,
        .prev:hover,
        tfoot tr th:hover {
            background: #{map-get($dark-grays, "200")};
        }
    }

    ngb-datepicker {
        border-color: #{map-get($dark-grays, "300")};
        .ngb-dp-header {
            background-color: #{map-get($dark-grays, "100")};
            .ngb-dp-arrow-btn {
                color: #{map-get($dark-grays, "600")};
            }
        }
        .ngb-dp-week .ngb-dp-weekdays {
            background-color: #{map-get($dark-grays, "100")};
        }

        .ngb-dp-week-number,
        .ngb-dp-weekday,
        .ngb-dp-day {
            color: #{map-get($dark-grays, "600")};
        }

        .ngb-dp-day,
        .ngb-dp-today {
            .btn-light {
                color: #{map-get($dark-grays, "700")};
            }
        }
    }
}
